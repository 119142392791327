<template>
  <div>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <h3 class="my-8">
      Tambah Latihan
    </h3>
    <v-card class="pa-4">
      <v-card-text>
        <div>
          <div class="mb-4">
            <h3>Tambah Latihan</h3>
            <span>Latihan akan ditambahkan ke dalam BAB</span>
          </div>

          <div>
            <v-text-field
              v-model="chapter.name"
              label="Judul"
              :rules="[validation.required]"
              outlined
              dense
            >
            </v-text-field>
          </div>
          <v-row>
            <v-col md="6">
              <v-menu
                v-model="deadline"
                :close-on-content-click="false"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="exercise.deadline"
                    label="Waktu Pengumpulan"
                    readonly
                    outlined
                    dense
                    :rules="[validation.required]"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click="autofillDeadline"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="exercise.deadline"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="deadline = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="6">
              <v-menu
                v-model="publish_at"
                :close-on-content-click="false"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="exercise.publish_at"
                    label="Waktu Penerbitan"
                    readonly
                    outlined
                    dense
                    :rules="[validation.required]"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click="autofillPublishAt"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="exercise.publish_at"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="publish_at = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <div>
            <v-textarea
              v-model="exercise.instruction"
              label="Instruksi"
              :rules="[validation.required]"
              outlined
              dense
            >
            </v-textarea>
          </div>
        </div>
        <div class="mb-4">
          <div class="mb-8">
            <h3>Soal</h3>
            <span>Soal untuk latihan</span>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="mb-4">
          <div class="mb-4 mt-7">
            <h3 class="font-weight-bold black--text">
              Soal Pilihan Ganda
            </h3>
          </div>
          <v-card
            v-for="questionItem in exerciseMultiple"
            v-show="exerciseMultiple.length"
            :key="questionItem.uuid"
            class="mb-2"
          >
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row class="align-center">
                    <v-col md="2">
                      <span>Soal No {{ questionItem.number }}</span>
                    </v-col>
                    <v-col md="4">
                      <v-chip
                        outlined
                        class="v-chip-light-bg success--text"
                        h-e-a-d
                        @click="checkDescription(questionItem.name)"
                      >
                        Lihat Deskripsi
                      </v-chip>
                    </v-col>
                    <v-col md="4">
                      <span>A.{{ questionItem.choice_1 }}</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>Pembahasan</p>
                  {{ questionItem.discussion }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </div>
        <div
          v-for="(question, index) of questions"
          :key="index"
        >
          <div>
            <div class="d-flex justify-space-between align-center mb-5">
              <div>
                <h4>
                  Soal Nomor {{ numberQuestion > 0 ? numberQuestion + (index+1) : index+1 }}
                </h4>
              </div>
              <div>
                <v-btn
                  color="primary pa-2"
                  icon
                  small
                  @click="deleteExerciseQuestion(index)"
                >
                  <v-icon>
                    {{ icons.mdiCloseCircleOutline }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div class="mb-4">
              <CKEditor
                :value="question[index].name"
                label="Content"
                outlined
                dense
                @input="handleCKeditor($event, index)"
              ></CKEditor>
            </div>
          </div>
          <div class="mb-4">
            <h4 class="mb-4">
              Masukkan Gambar (jika ada)
            </h4>
            <FileInput
              :value="question[index].image"
              label="Gambar"
              outlined
              dense
              :disabled="false"
              :prependicon="icons.mdiImageMultipleOutline"
              @input="getFile"
            ></FileInput>
          </div>
          <div>
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <h4 class="mb-5 mt-8">
                  Kunci Jawaban
                </h4>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    class="py-2"
                  >
                    <div>
                      <v-text-field
                        v-model="question[index].choice_a"
                        label="Jawaban"
                        :rules="[validation.required]"
                        outlined
                        dense
                      >
                        <span
                          slot="prepend"
                          class="pt-1"
                        >
                          A.
                        </span>
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    class="py-2"
                  >
                    <div>
                      <v-text-field
                        v-model="question[index].choice_b"
                        label="Jawaban"
                        :rules="[validation.required]"
                        outlined
                        dense
                      >
                        <span
                          slot="prepend"
                          class="pt-1"
                        >
                          C.
                        </span>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    class="py-2"
                  >
                    <div>
                      <v-text-field
                        v-model="question[index].choice_c"
                        label="Jawaban"
                        :rules="[validation.required]"
                        outlined
                        dense
                      >
                        <span
                          slot="prepend"
                          class="pt-1"
                        >
                          B.
                        </span>
                      </v-text-field>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    class="py-2"
                  >
                    <div>
                      <v-text-field
                        v-model="question[index].choice_d"
                        label="Jawaban"
                        :rules="[validation.required]"
                        outlined
                        dense
                      >
                        <span
                          slot="prepend"
                          class="pt-1"
                        >
                          D.
                        </span>
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
                xl="6"
                class="py-0"
              >
                <h4 class="mb-4">
                  Jawaban Yang Benar
                </h4>
                <div class="">
                  <v-text-field
                    v-model="question[index].answer"
                    label="Jawaban yang benar"
                    :rules="[validation.required]"
                    outlined
                    dense
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
                xl="6"
                class="py-0"
              >
                <h4 class="mb-4">
                  Point Jawaban Yang Benar
                </h4>
                <div>
                  <v-text-field
                    v-model="question[index].point"
                    label="Point"
                    :rules="[validation.required]"
                    type="number"
                    outlined
                    dense
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <h4 class="mb-4">
                  Pembahasan
                </h4>
                <div>
                  <div>
                    <v-textarea
                      v-model="question[index].discussion"
                      label="Pembahasan"
                      :rules="[validation.required]"
                      outlined
                      dense
                    >
                    </v-textarea>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="mb-6">
            <v-switch
              v-model="question[index].archive"
              :label="`${question[index].archive?'Disimpan': 'Tidak Disimpan'} Ke Arsip `"
            ></v-switch>
          </div>
        </div>
        <div>
          <div
            v-show="isHide"
          >
            <v-card-actions class="d-flex justify-center mb-0">
              <v-btn
                color="primary"
                large
                :loading="isLoadingButton"
                :disabled="!formValidExercise || !formValidChapter"
                @click="addQuestion()"
              >
                Simpan
              </v-btn>
              <v-btn
                color="primary"
                outlined
                large
                @click="deleteExerciseQuestion()"
              >
                Batal
              </v-btn>
            </v-card-actions>
          </div>
          <div class="mb-8 mt-8">
            <a
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              @click="addExerciseQuestion"
            ><v-icon color="primary">{{ icons.mdiPlus }}</v-icon> TAMBAH SOAL</a>
            <a
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              @click="showFormArchive"
            ><v-icon color="primary">{{ icons.mdiPlus }}</v-icon> AMBIL DARI ARSIP SOAL</a>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="mb-8 mt-8">
          <div class="mb-4">
            <div class="mb-4">
              <h3 class="mb-4 mt-8">
                Soal Essai
              </h3>
            </div>
            <v-card
              v-for="questionEssayItem in exerciseEssay"
              v-show="exerciseEssay.length "
              :key="questionEssayItem.uuid"
              class="mb-2"
            >
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col md="2">
                        <span>Soal No {{ questionEssayItem.number }}</span>
                      </v-col>
                      <v-col md="4">
                        <v-chip
                          outlined
                          class="v-chip-light-bg success--text"
                          h-e-a-d
                          @click="checkDescription(questionEssayItem.name)"
                        >
                          Lihat Deskripsi
                        </v-chip>
                      </v-col>
                      <v-col md="4">
                        <span>{{ questionEssayItem.name }}</span>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>Pembahasan</p>
                    {{ questionEssayItem.discussion }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
          <div
            v-for="(questEssay, index) of questionsEssay"
            :key="index"
          >
            <div class="d-flex justify-space-between align-center mb-5">
              <div>
                <h4>
                  Soal Nomor {{ numberQuestionEssay > 0 ? numberQuestionEssay + (index+1) : index+1 }}
                </h4>
              </div>
              <div>
                <v-btn
                  color="primary pa-2"
                  icon
                  small
                  @click="deleteExerciseEssay(index)"
                >
                  <v-icon>
                    {{ icons.mdiCloseCircleOutline }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div>
              <v-text-field
                v-model="questEssay[index].name"
                label="Soal"
                :rules="[validation.required]"
                outlined
                type="text"
                dense
              >
              </v-text-field>
            </div>
            <div>
              <v-textarea
                v-model="questEssay[index].discussion"
                label="Pembahasan"
                :rules="[validation.required]"
                outlined
                dense
              >
              </v-textarea>
            </div>
            <div class="mb-4">
              <h4 class="mb-4">
                Masukkan Gambar (jika ada)
              </h4>
              <FileInput
                :value="questEssay[index].image"
                label="Gambar"
                outlined
                dense
                :disabled="false"
                :prependicon="icons.mdiImageMultipleOutline"
                @input="getFileEssay"
              ></FileInput>
            </div>
            <div class="mb-6">
              <v-switch
                v-model="questEssay[index].archive"
                :label="`${questEssay[index].archive?'Disimpan': 'Tidak Disimpan'} Ke Arsip`"
              ></v-switch>
            </div>
          </div>
        </div>
        <div>
          <div
            v-show="isHideEssay"
          >
            <v-card-actions class="d-flex justify-center mb-0">
              <v-btn
                color="primary"
                large
                :loading="isLoadingButton"
                :disabled="isDisabledButton"
                @click="addQuestionEssay()"
              >
                Simpan
              </v-btn>
              <v-btn
                color="primary"
                outlined
                large
                @click="deleteExerciseEssay()"
              >
                Batal
              </v-btn>
            </v-card-actions>
          </div>
          <div class="mb-8 mt-8">
            <a
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              @click="addExerciseQuestionEssay"
            ><v-icon color="primary">{{ icons.mdiPlus }}</v-icon> TAMBAH SOAL</a>
          </div>
          <v-divider></v-divider>
        </div>
      </v-card-text>
    </v-card>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      header="Edit Pertanyaan"
      text-button="Update"
      :is-loading-button="isLoadingButton"
      @handler-button="updateQuestion"
    >
      <template v-slot:body>
        <div v-if="type =='choice'">
          <v-text-field
            v-model="questionEdit.number"
            label="Number"
            type="number"
            outlined
            dense
          >
          </v-text-field>
        </div>
        <div v-if="type =='essay'">
          <v-text-field
            v-model="questionEditEssay.number"
            label="Number"
            type="number"
            outlined
            dense
          >
          </v-text-field>
        </div>
        <div v-if="type =='essay'">
          <v-text-field
            v-model="questionEditEssay.name"
            label="Soal"
            type="text"
            outlined
            dense
          >
          </v-text-field>
        </div>
        <div v-if="type =='essay'">
          <v-textarea
            v-model="questionEditEssay.discussion"
            label="Pembahasan"
            type="text"
            outlined
            dense
          >
          </v-textarea>
        </div>
        <div v-if="type =='choice'">
          <CKEditor
            :value="initialContent"
            label="Soal"
            outlined
            dense
            @input="handleCKeditor($event, 'edit')"
          ></CKEditor>
        </div>
        <div class="mb-4">
          <div>
            <h4 class="mb-4">
              Masukkan Gambar (jika ada)
            </h4>
            <FileInput
              v-if="type =='choice'"
              v-model="initialFile"
              dense
              outlined
              :disabled="false"
              :prependicon="icons.mdiImageMultipleOutline"
              label="Gambar"
              @input="getFile"
            >
            </FileInput>
            <FileInput
              v-if="type =='essay'"
              v-model="initialFileEssay"
              dense
              outlined
              :disabled="false"
              :prependicon="icons.mdiImageMultipleOutline"
              label="Gambar"
              @input="getFileEssay"
            >
            </FileInput>

            <div v-if="type =='choice'">
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <h4 class="mb-5 mt-8">
                    Kunci Jawaban
                  </h4>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="py-2"
                    >
                      <div>
                        <v-text-field
                          v-model="questionEdit.choice_a"
                          label="Jawaban"
                          outlined
                          dense
                        >
                          <span
                            slot="prepend"
                            class="pt-1"
                          >
                            A.
                          </span>
                        </v-text-field>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="py-2"
                    >
                      <div>
                        <v-text-field
                          v-model="questionEdit.choice_b"
                          label="Jawaban"
                          outlined
                          dense
                        >
                          <span
                            slot="prepend"
                            class="pt-1"
                          >
                            C.
                          </span>
                        </v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="py-2"
                    >
                      <div>
                        <v-text-field
                          v-model="questionEdit.choice_c"
                          label="Jawaban"
                          outlined
                          dense
                        >
                          <span
                            slot="prepend"
                            class="pt-1"
                          >
                            B.
                          </span>
                        </v-text-field>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="py-2"
                    >
                      <div>
                        <v-text-field
                          v-model="questionEdit.choice_d"
                          label="Jawaban"
                          outlined
                          dense
                        >
                          <span
                            slot="prepend"
                            class="pt-1"
                          >
                            D.
                          </span>
                        </v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-0"
                >
                  <h4 class="mb-4">
                    Jawaban Yang Benar
                  </h4>
                  <div class="">
                    <v-text-field
                      v-model="questionEdit.answer.answer"
                      label="Jawaban yang benar"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-0"
                >
                  <h4 class="mb-4">
                    Point Jawaban Yang Benar
                  </h4>
                  <div>
                    <v-text-field
                      v-model="questionEdit.answer.point"
                      label="Point"
                      type="number"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <h4 class="mb-4">
                    Pembahasan
                  </h4>
                  <div>
                    <div v-if="type =='choice'">
                      <v-textarea
                        v-model="questionEdit.discussion"
                        label="Pembahasan"
                        outlined
                        dense
                      >
                      </v-textarea>
                    </div>
                    <div v-if="type =='essay'">
                      <v-textarea
                        v-model="questionEditEssay.discussion"
                        label="Pembahasan"
                        outlined
                        dense
                      >
                      </v-textarea>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDescription"
      :width="widthModal"
      header="Deskripsi Soal"
      :hidden-actions="false"
      @close-modal="modalDescription = false"
    >
      <template v-slot:body>
        <p v-html="detail"></p>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDescription = false"
        >
          Tutup
        </v-btn>
      </template>
    </ModalDialog>
    <ModalDialog
      header="PILIH SOAL"
      :is-loading-button="isLoadingButton"
      text-button="Export"
      :visible="archiveModalDialog"
      :width="800"
      @handler-button="archiveExport"
      @close-modal="archiveModalDialog = false"
      @close-button="archiveModalDialog = false"
    >
      <template v-slot:body>
        <span>Pilih soal dari Arsip Soal yang akan anda masukan kedalam soal latihan </span>
        <v-row class="mt-4">
          <v-col md="8">
            <div>
              <v-text-field
                v-model="search"
                label="Cari Soal"
                outlined
                dense
              ></v-text-field>
            </div>
          </v-col>
          <v-col md="4">
            <div class="d-md-flex">
              <span class="mt-2 me-2">Kategori:</span>
              <div>
                <v-autocomplete
                  outlined
                  dense
                  label="Semua"
                ></v-autocomplete>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-simple-table
          class="mb-4 overflow-x-auto"
          height="400"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  <v-checkbox
                    v-model="selectAll"
                  ></v-checkbox>
                </th>
                <th class="text-left">
                  Soal
                </th>
                <th class="text-left">
                  Kategori
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(archive, index) in filteredList"
                :key="index"
              >
                <td>
                  <v-checkbox
                    v-model="selected"
                    :value="archive.uuid"
                    @change="handleCheckboxChange(archive.uuid, selected)"
                  ></v-checkbox>
                </td>
                <td>
                  <div v-html="archive.name"></div>
                </td>
                <td>{{ archive.is_essay === '1' ? "Essay" : "Pilihan Ganda" }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card
          width="180"
          height="50"
          style="border: 1px dashed #C4C4C4;"
          class="d-flex align-center text-center pa-4"
        >
          Soal yang dipilih :  {{ totalArchiveQuestion ? totalArchiveQuestion : 0 }}
        </v-card>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      header="Hapus Soal"
      :is-loading-button="isLoadingButton"
      @destroy-button="destroyQuestion"
      @close-button="confirmDialog = false"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import CKEditor from '@/views/components/CkEditor.vue'
import { integerValidator, required } from '@core/utils/validation'

import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import FileInput from '@/views/components/FileInput.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import {
  mdiArrowLeft,
  mdiCloseCircleOutline,
  mdiFlaskEmptyPlusOutline,
  mdiImageMultipleOutline,
  mdiNoteOutline,
  mdiPlus,
} from '@mdi/js'

export default {
  name: 'ElearningExerciseAdd',
  components: {
    ModalDialog,
    ConfirmDialog,
    FileInput,
    CKEditor,
  },
  data() {
    return {
      modalDescription: false,
      widthModal: 810,
      icons: {
        mdiNoteOutline,
        mdiFlaskEmptyPlusOutline,
        mdiPlus,
        mdiCloseCircleOutline,
        mdiImageMultipleOutline,
        mdiArrowLeft,
      },
      validation: {
        required,
        integerValidator,
      },
      search: '',
      totalArchiveQuestion: 0,
      modalDialog: false,
      confirmDialog: false,
      archiveModalDialog: false,
      width: 600,
      isHide: false,
      isHideEssay: false,
      isLoadingButton: false,
      isDisabledButton: false,
      status: true,
      deadline: false,
      publish_at: false,
      type: '',
      archiveQuestion: [],
      archiveToAdd: [],
      archiveToRemove: [],
      selected: [],
      chapter: {
        uuid: '',
        chapter_of: '',
        type: 'exercise',
        name: '',
      },
      dialog: '',
      chapterUuid: '',
      chapterLength: 0,
      chapters: [],
      exercise: {
        uuid: '',
        chapter_uuid: '',
        title: '',
        deadline: '',
        instruction: '',
        publish_at: '',
        question: [],
      },
      exerciseEssay: [],
      exerciseMultiple: [],
      questionItems: [],
      questions: [],
      question: [],
      questionImage: [],
      questionEdit: {
        answer_key: {
          uuid: '',
          point: 0,
          answer_key: '',
        },
      },
      filtered: [],
      questionEditEssay: {},
      questionsEssay: [],
      questionEssayItems: [],
      questionEssay: [],
      questionImageEssay: [],
      answer: [],
      numberQuestion: 0,
      numberQuestionEssay: 0,
      tempContent: '',
      tempImage: [],
      detail: '',
      formValidExercise: false,
      formValidChapter: false,
      formValidQuestion: false,
    }
  },
  computed: {
    filteredList() {
      return this.setArchive()
    },
    initialContent: {
      get() {
        if (this.questionEdit.name === null) {
          return ''
        }

        return this.questionEdit.name
      },
      set() {
        return this.questionEdit.name
      },
    },
    initialFile: {
      get() {
        if (typeof this.questionEdit.image === 'string') {
          return new File([this.questionEdit.image], this.questionEdit.image, { type: 'text/plain' })
        }

        return this.questionEdit.image
      },
      set() {
        return this.questionEdit.image
      },
    },
    initialFileEssay: {
      get() {
        if (typeof this.questionEditEssay.image === 'string') {
          return new File([this.questionEditEssay.image], this.questionEditEssay.image, { type: 'text/plain' })
        }

        return this.questionEditEssay.image
      },
      set() {
        return this.questionEditEssay.image
      },
    },
    selectAll: {
      get() {
        return this.totalArchiveQuestion ? this.selected.length === this.filtered.length : false
      },
      set(value) {
        const selected = []
        this.totalArchiveQuestion = 0
        if (value) {
          this.filtered.forEach(archive => {
            selected.push(archive.uuid)
            this.totalArchiveQuestion += 1

            // selected.push(archive.uuid)
          })
        } else {
          this.selected = []
          this.totalArchiveQuestion = 0
        }

        this.selected = selected
      },
    },
  },
  watch: {
    exercise: {
      handler() {
        const valid = []
        const requiredField = ['instruction', 'deadline']
        Object.entries(this.exercise).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValidExercise = !valid.includes(false)
      },
      deep: true,
    },
    chapter: {
      handler() {
        const valid = []
        const requiredField = ['name']
        Object.entries(this.chapter).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValidChapter = !valid.includes(false)
      },
      deep: true,
    },
    question: {
      handler() {
        const valid = []
        const requiredField = ['name', 'choice_a', 'choice_b', 'choice_c', 'choice_d', 'answer', 'point', 'discussion']
        Object.entries(this.chapter).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValidQuestion = !valid.includes(false)
      },
      deep: true,
    },
  },
  mounted() {
    this.getChapterLength()
    this.redirect()
    localStorage.removeItem('chapter')
    localStorage.removeItem('exercise')
    localStorage.removeItem('question')
  },
  methods: {
    checkDescription(data) {
      this.detail = data

      this.modalDescription = true
    },

    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('news', fileName).then(({ data }) => true)
    },
    handleCKeditor(event, index) {
      if (index !== 'edit') {
        this.question[index].name = event
      } else {
        this.questionEdit.name = event
      }

      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },
    redirect() {
      if (JSON.parse(localStorage.getItem('exercise')).uuid) {
        window.onload = () => {
          this.showSnackbar({
            text: 'Data mu berhasil disimpan',
            color: 'success',
          })
          this.$router.back()
        }
      }
    },
    setArchive() {
      this.selected = []

      this.filtered = this.archiveQuestion.filter(post => post.name.toLowerCase().includes(this.search.toLowerCase()))

      this.totalArchiveQuestion = 0

      return this.filtered
    },
    returnBack() {
      return this.$router.back()
    },
    getChapterLength() {
      this.$services.ApiServices.get('section', this.$route.params.uuid).then(
        ({ data }) => {
          this.chapterLength = data.data.chapter.length + 1
        },
        err => console.error(err),
      )
    },
    getFile(data) {
      this.questionEdit.image = data
      this.questionImage.push(data)
    },
    getFileEssay(data) {
      this.questionEditEssay.image = data
      this.questionImageEssay.push(data)
    },
    showFormArchive() {
      if (this.exercise.uuid === '') {
        this.showSnackbar({
          text: 'Please, Add exercise first!!',
          color: 'error',
        })

        return
      }
      this.search = ''
      this.selected = []
      this.getQuestionArchive()
      this.isDisabledButton = false
      this.archiveModalDialog = true
    },
    handleCheckboxChange(archiveUuid, isSelected) {
      this.totalArchiveQuestion = isSelected.length
    },
    async getQuestion() {
      await this.$services.ApiServices.get('exercise', this.exercise.uuid).then(
        ({ data }) => {
          this.exercise = data.data
        },
        err => console.error(err),
      )
    },
    async getQuestionArchive(search) {
      if (typeof search === 'undefined') {
        search = ''
      }
      await this.$services.ApiServices.list('archivequestion', { search }).then(
        ({ data }) => {
          this.archiveQuestion = data.data.filter(item => item.is_essay === null)
        },
        err => console.error(err),
      )
    },

    async addChapter() {
      this.isLoadingButton = true
      this.chapter.section_uuid = this.$route.params.uuid
      this.chapter.chapter_of = this.chapterLength
      await this.$services.ApiServices.add('chapter', this.chapter).then(
        ({ data }) => {
          this.chapter = data.data
          localStorage.setItem('chapter', JSON.stringify(data.data))
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },
    async addExercise() {
      this.isLoadingButton = true
      this.exercise.chapter_uuid = this.chapter.uuid
      this.exercise.title = this.chapter.name
      this.exercise.deadline = `${this.exercise.deadline} 00:00`
      await this.$services.ApiServices.add('exercise', this.exercise).then(
        ({ data }) => {
          this.exercise = data.data
          localStorage.setItem('exercise', JSON.stringify(data.data))
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },
    async getExercise() {
      await this.$services.ApiServices.get('exercise', this.exercise.uuid).then(
        ({ data }) => {
          this.exercise = data.data
          this.exercise.deadline = data.data.deadline.slice(0, 10)
          this.exercise.publish_at = data.data.publish_at.slice(0, 10)
          this.exerciseMultiple = this.exercise.question.filter(item => item.is_essay === null)
          this.exerciseMultiple.sort((a, b) => a.number - b.number)
          this.exerciseEssay = this.exercise.question.filter(item => item.is_essay)
          this.exerciseEssay.sort((a, b) => a.number - b.number)
        },
        err => console.error(err),
      )
    },
    async archiveExport() {
      this.isLoadingButton = true

      this.formData = new FormData()
      this.formData.append('type', 'exercise')
      this.formData.append('type_uuid', this.exercise.uuid)
      this.selected.forEach(item => {
        this.formData.append('archive_uuid[]', item)
      })
      await this.$services.ApiServices.export('archivequestion', this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Export archive to question successfully',
            color: 'success',
          })
          this.isDisabledButton = true
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      await this.getExercise()
      this.numberQuestion = this.exerciseMultiple.length
      this.archiveModalDialog = false
      this.isLoadingButton = false
    },
    async addQuestion() {
      this.isLoadingButton = true
      if (this.chapter.uuid === '') {
        await this.addChapter()
      }
      if (this.exercise.uuid === '') {
        await this.addExercise()
      }
      this.question.forEach(async (item, index) => {
        item.type = 'exercise'
        item.type_uuid = this.exercise.uuid
        if (this.questionItem) {
          item.type_uuid = JSON.parse(localStorage.getItem('exercise')).uuid
        }

        item.number = this.exerciseMultiple.length + (index + 1)
        this.formData = new FormData()
        this.formData.append('type', item.type)
        this.formData.append('type_uuid', item.type_uuid)
        this.formData.append('number', item.number)
        this.formData.append('is_archive', item.archive ? 1 : 0)
        this.formData.append('answer', item.answer)
        this.formData.append('point', item.point)
        this.formData.append('name', item.name)
        this.formData.append('discussion', item.discussion)
        this.formData.append('image', this.questionImage[index])
        if (typeof this.questionImage[index] === 'undefined') {
          this.formData.delete('image')
        }
        this.formData.append('choice_a', item.choice_a)
        this.formData.append('choice_b', item.choice_b)
        this.formData.append('choice_c', item.choice_c)
        this.formData.append('choice_d', item.choice_d)
        const tempImage = this.tempContent.split('"')
        tempImage.forEach(async item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            this.tempImage.push(img)
          }
        })
        const image = item.name.split('"')
        image.forEach(item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            const carIndex = this.tempImage.indexOf(img)
            this.tempImage.splice(carIndex, 1)

            this.tempImage.forEach(async item => {
              await this.deleteStorage({ file: item })
            })
          }
        })
        await this.$services.ApiServices.add('question', this.formData).then(
          async ({ data }) => {
            this.showSnackbar({
              text: 'Add question has successfully',
              color: 'success',
            })
          },
          err => {
            console.error(err)
            this.isLoadingButton = false
          },
        )
        this.isLoadingButton = false
        this.isHide = false
        await this.getExercise()
        this.numberQuestion = this.exerciseMultiple.length
        this.questions.splice(index, this.questions.length)
        if (this.questions.length < 1) {
          this.isHide = false
        }
        this.question = []
        this.answer = []
      })
      this.questionImage = []
      this.isLoadingButton = false
      this.isDisabledButton = true
      // this.returnBack()
    },
    async addQuestionEssay() {
      this.isLoadingButton = true
      if (this.chapter.uuid === '') {
        await this.addChapter()
      }
      if (this.exercise.uuid === '') {
        await this.addExercise()
      }
      this.questionEssay.forEach(async (item, index) => {
        item.type = 'exercise'
        item.type_uuid = this.exercise.uuid
        if (this.questionEssayItem) {
          item.type_uuid = JSON.parse(localStorage.getItem('exercise')).uuid
        }
        item.number = this.exerciseEssay.length + (index + 1)
        item.is_essay = 1
        this.formData = new FormData()
        this.formData.append('type', item.type)
        this.formData.append('type_uuid', item.type_uuid)
        this.formData.append('number', item.number)
        this.formData.append('name', item.name)
        this.formData.append('discussion', item.discussion)
        this.formData.append('is_essay', item.is_essay)
        this.formData.append('image', this.questionImageEssay[index])
        if (typeof this.questionImageEssay[index] === 'undefined') {
          this.formData.delete('image')
        }
        this.formData.append('is_archive', item.archive ? 1 : 0)

        await this.$services.ApiServices.add('question', this.formData).then(
          async ({ data }) => {
            this.showSnackbar({
              text: 'Add question essay has successfully',
              color: 'success',
            })
          },
          err => {
            console.error(err)
            this.isLoadingButton = false
            console.error(err)
          },
        )
        this.isLoadingButton = false
        this.isHideEssay = false
        await this.getExercise()
        this.questionEssay = []
        this.numberQuestionEssay = this.exerciseEssay.length
        this.questionsEssay.splice(index, this.questionsEssay.length)
        if (this.questionsEssay.length < 1) {
          this.isHide = false
        }
      })
      this.isLoadingButton = false
      this.isDisabledButton = true
      this.questionImageEssay = []

      const a = []

      if (typeof localStorage.getItem('question') !== 'string') {
        localStorage.setItem('question', JSON.stringify(this.question))
      } else {
        JSON.parse(localStorage.getItem('question')).forEach(item => {
          a.push(item)
        })
        this.question.forEach(item => {
          a.push(item)
        })
        localStorage.setItem('question', JSON.stringify(a))
      }
    },
    async editQuestion(uuid) {
      this.tempContent = ''
      this.tempImage = []
      this.type = 'choice'
      this.modalDialog = true
      this.questionEdit.uuid = uuid
      this.$services.ApiServices.get('question', uuid).then(({ data }) => {
        this.questionEdit = data.data
        const image = data.data.name.split('"')
        image.forEach(async item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            this.tempImage.push(img)
          }
        })
      })
    },
    async editQuestionEssay(uuid) {
      this.type = 'essay'
      this.modalDialog = true
      this.questionEditEssay.uuid = uuid
      this.$services.ApiServices.get('question', uuid).then(({ data }) => {
        this.questionEditEssay = data.data
      })
    },

    async updateQuestion() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('number', this.questionEdit.number)
      this.formData.append('name', this.questionEdit.name)
      this.formData.append('choice_a', this.questionEdit.choice_a)
      this.formData.append('choice_b', this.questionEdit.choice_b)
      this.formData.append('choice_c', this.questionEdit.choice_c)
      this.formData.append('choice_d', this.questionEdit.choice_d)
      this.formData.append('discussion', this.questionEdit.discussion)
      this.formData.append('image', this.questionEdit.image)
      if (typeof this.questionEdit.image === 'string') {
        this.formData.delete('image')
      }
      const image = this.questionEdit.name.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      await this.$services.ApiServices.update('question', this.formData, this.questionEdit.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Update question choice has successfully',
            color: 'success',
          })
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      if (this.questionEdit.answer_key.answer !== '') {
        await this.$services.ApiServices.update(
          'answer',
          this.questionEdit.answer_key,
          this.questionEdit.answer_key.uuid,
        ).then(
          ({ data }) => {
            this.showSnackbar({
              text: data.result.message,
              color: 'success',
            })
          },
          err => {
            console.error(err)
            this.isLoadingButton = false
          },
        )
      }
      this.isLoadingButton = false
      this.modalDialog = false
      await this.getExercise()
    },
    async updateQuestionEssay(uuid) {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('number', this.questionEditEssay.number)
      this.formData.append('name', this.questionEditEssay.name)
      this.formData.append('discussion', this.questionEditEssay.discussion)
      this.formData.append('image', this.questionEditEssay.image)
      if (typeof this.questionEditEssay.image === 'string') {
        this.formData.delete('image')
      }
      await this.$services.ApiServices.update('question', this.formData, uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Update question essay has successfully',
            color: 'success',
          })
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
      this.modalDialog = false
      await this.getExercise()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.question.uuid = uuid
    },
    async destroyQuestion() {
      this.isLoadingButton = true
      this.question.uuid = uuid
      await this.$services.ApiServices.destroy('question', this.question.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          let list
          const questionList = this.question.filter(el => {
            list = JSON.parse(JSON.stringify(el))

            return list.uuid !== data.data.uuid
          })
          this.question = JSON.parse(JSON.stringify(questionList))
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      this.isLoadingButton = false
      this.confirmDialog = false
      await this.getExercise()
    },

    addExerciseQuestion() {
      this.tempContent = ''
      this.tempImage = []
      this.isDisabledButton = false
      this.question = [
        ...this.question,
        {
          type: 'exercise',
          type_uuid: '',
          number: 0,
          name: '',
          choice_a: '',
          choice_b: '',
          choice_c: '',
          choice_d: '',
          discussion: '',
        },
      ]
      this.answer = [
        ...this.answer,
        {
          question_uuid: '',
          answer: '',
          point: 0,
        },
      ]
      this.question.number = this.exerciseMultiple.length + 1
      this.questions.push(this.question)
      this.isHide = true
    },
    addExerciseQuestionEssay() {
      this.isDisabledButton = false
      this.questionEssay = [
        ...this.questionEssay,
        {
          type: 'exercise',
          type_uuid: '',
          number: 0,
          name: '',
          discussion: '',
          is_essay: 1,
          image: null,
        },
      ]
      this.questionEssay.number = this.exerciseEssay.length + 1
      this.questionsEssay.push(this.questionEssay)
      this.isHideEssay = true
    },
    deleteExerciseQuestion(index) {
      const image = this.tempContent.split('"')
      image.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const fileName = {
            file: img,
          }
          await this.deleteStorage(fileName)
        }
      })
      this.tempContent = ''
      this.questions.splice(index, 1)
      if (this.questions.length < 1) {
        this.isHide = false
      }
    },
    deleteExerciseEssay(index) {
      this.questionsEssay.splice(index, 1)
      if (this.questionsEssay.length < 1) {
        this.isHideEssay = false
      }
    },
    autofillDeadline() {
      this.exercise.deadline = new Date().toISOString().substr(0, 10)
    },
    autofillPublishAt() {
      this.exercise.publish_at = new Date().toISOString().substr(0, 10)
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 15px !important;
}
</style>
