<template>
  <div>
    <v-file-input
      v-model="model"
      :label="label"
      :prepend-icon="prependicon"
      :value="value"
      :disabled="disabled"
      :messages="message"
      :accept="accept"
      outlined
      dense
    >
    </v-file-input>
  </div>
</template>

<script>
import { mdiPaperclip } from '@mdi/js'

export default {
  name: 'FileInput',
  props: {
    value: {
      type: null,
      default: () => null,
    },
    message: {
      type: String,
      default: () => 'File .jpg/.png, max. 2MB',
    },
    accept: {
      type: String,
      default: () => '.png,.gif,.jpeg,.jpg,.svg',
    },
    label: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    prependicon: {
      type: String,
      default: () => this.icon.mdiPaperclip,
    },
  },
  data() {
    return {
      icons: {
        mdiPaperclip,
      },
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style>
</style>
